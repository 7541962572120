@tailwind base;
@tailwind components;
@tailwind utilities;

@import "./fonts.scss";

* {
  font-family:
    "Montserrat",
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    "Roboto",
    "Oxygen",
    "Ubuntu",
    "Cantarell",
    "Fira Sans",
    "Droid Sans",
    "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-variant-numeric: lining-nums proportional-nums;
}

body {
  background-color: #ffff;
  margin: 0;
}

pre {
  font-family:
    "Raleway",
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    "Roboto",
    "Oxygen",
    "Ubuntu",
    "Cantarell",
    "Fira Sans",
    "Droid Sans",
    "Helvetica Neue",
    sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

input {
  outline: none;
}
p {
  @apply text-blue-dark;
}

.container {
  @apply mx-auto box-border w-full max-w-295 overflow-hidden px-5;
}

h1 {
  @apply text-64 font-semibold text-black-ligth;
}

h2 {
  @apply text-46 font-semibold text-black-ligth;
}

h3 {
  @apply text-32 font-semibold text-black-ligth;
}

//swiper styles

.swiper-pagination {
  position: relative !important;
  width: auto !important;
  bottom: 0 !important;
  height: auto !important;
}

.swiper-pagination-bullet {
  width: 50px;
  border-radius: 0px !important;
  // margin: 0 4px;
  background: #0303031a !important;
  height: 4px;
  opacity: 1 !important;
  background: #e4f0f9 !important;
  transition: all 0.3s ease;
}

.swiper-pagination-bullet-active {
  transition: all 0.3s ease;
  width: 100px;
  color: #fff;
  background: #f4b10b !important;
}

//swiper reviews

.swiper-pagination-reviews {
  position: relative !important;
  width: auto !important;
  bottom: 0 !important;
  height: auto !important;
}

.swiper-pagination-reviews-bullet {
  width: 25px;
  border-radius: 0px !important;
  // margin: 0 4px;
  background: #0303031a !important;
  height: 4px;
  opacity: 1 !important;
  background: #e4f0f9 !important;
  transition: all 0.3s ease;
}

.swiper-pagination-reviews-bullet-active {
  transition: all 0.3s ease;
  width: 50px;
  color: #fff;
  background: #f4b10b !important;
}
