@mixin font-face($font-family, $file-name, $font-weight) {
  @font-face {
    font-family: $font-family;
    src: url("../assets/fonts/#{$font-family}/#{$file-name}");
    font-style: normal;
    font-weight: $font-weight;
    font-display: swap;
  }
}

// Montserrat font family
@include font-face("Montserrat", "Montserrat-Bold.ttf", 700);
@include font-face("Montserrat", "Montserrat-SemiBold.ttf", 600);
@include font-face("Montserrat", "Montserrat-Medium.ttf", 500);
@include font-face("Montserrat", "Montserrat-Regular.ttf", 400);

// OpenSans font family
@include font-face("OpenSans", "OpenSans-Regular.ttf", 400);
@include font-face("OpenSans", "OpenSans-Light.ttf", 300);
